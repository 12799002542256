export const alert = {
    namespaced: true,
    state: {
        type: null,
        message: null,
        validationErrors: null,
    },
    actions: {
        success({ commit }, message) {
            commit('success', message);
        },
        error({ commit }, message) {
            commit('error', message);
        },
        validationError({ commit }, message) {
            commit('validationError', message);
        },
        clear({ commit }) {
            commit('clear');
        }
    },
    mutations: {
        success(state, message) {
            state.type = 'alert-success';
            state.message = message;
        },
        error(state, message) {
            state.type = 'alert-danger';
            state.message = message;
        },
        validationError(state, message) {
            state.type = 'alert-validation';
            state.validationErrors = message['validation'];
        },
        clear(state) {
            state.type = null;
            state.message = null;
        }
    }
}