import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: "/",

    routes: [
        {
            path: '/',
            name: 'Home page',
            meta: { gtm: 'GHome page' },
            component: () => import('./views/template/HomeTemplate'),
            children: [
                // Main
                {
                    name: 'Main',

                    path: '',
                    component: () => import('./views/Home'),
                },
            ],

        },
        {
            path: '/search',

            component: () => import('./views/template/SearchTemplate'),
            meta: {
                permissions: [
                    {
                        role: "guest",
                        access: false,
                        redirect: "Main"
                    }
                ]
            },
            children: [
                // Main
                {
                    name: 'Search',
                    meta: { gtm: 'GSearch' },
                    path: '',
                    component: () => import('./views/Search'),

                },
            ]
        },
        {
            path: '/login',
            component: () => import('./components/auth/Login'),
            meta: { gtm: 'GLogin' },
            name:'Login',
            beforeEnter: (to, from, next) => {
                noAccessIfLogin(next);
            },
        },
        {
            path: '/registrate',
            component: () => import('./components/auth/Registrate'),
            meta: { gtm: 'GRegistration' },
            name:'Registration',
            beforeEnter: (to, from, next) => {
                noAccessIfLogin(next);
            },
        },
        {
            name: 'AboutUs',
            path: '/about-us',
            meta: { gtm: 'GAboutUs' },
            component: () => import('./components/home/AboutUs'),
        },
        {
            name: 'CheckEmail',
            meta: { gtm: 'GCheckEmail' },
            path: '/check-email',
            component: () => import('./components/auth/CheckEmail'),
        },
        {
            name: 'SetNewPassword',
            meta: { gtm: 'GSetNewPassword' },
            path: '/set-new-password',
            component: () => import('./components/auth/SetNewPassword'),
        },
        {
            path: '/recovery-password',
            name: 'RecoveryPassword',
            meta: { gtm: 'GRecoveryPassword' },
            component: () => import('./components/auth/RecoveryPassword'),
        },
        {
            path: '/auth/:provider/callback',
            component: {
                template: '<div class="auth-component"></div>'
            }
        },
        {
            path: '/auth/callback',
            component: {
                template: '<div class="auth-component"></div>'
            }
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            meta: { gtm: 'GDashboard' },
            component: () => import('./views/template/DashboardTemplate'),
            // meta: {
            //     permissions: [
            //         {
            //             role: "guest",
            //             access: false,
            //             redirect: "login"
            //         }
            //     ]
            // },
            children: [
                // Main
                {
                    path: 'profile',
                    name: 'Profile',
                    meta: { gtm: 'GProfile' },
                    component: () => import('./components/dashboard/Profile'),
                    beforeEnter: (to, from, next) => {
                        isLogin(next);
                    },

                },
                {
                    name: 'DashboardTabs',
                    path: '/',
                    component: () => import('./components/dashboard/DashboardTabs'),
                    beforeEnter: (to, from, next) => {
                        isLogin(next);
                    },

                },
            ],
            // beforeEnter: (to, from, next) => {
            //     noAccessIfLogin(next);
            // },
        },
        {
            path: '/subscribe',
            name: 'Subscribe',
            meta: { gtm: 'GSubscribe' },
            component: () => import('./components/home/Subscribe'),
        },

        // {
        //     path: '/profile',
        //     component: () => import('./components/dashboard/Profile'),
        //     beforeEnter: (to, from, next) => {
        //         isLogin(next);
        //     },
        // },


    ]
})

function noAccessIfLogin(next) {
    if (localStorage.getItem('user') != null) {
        next('/');
    } else {
        next();
    }
}

//
function isLogin(next) {
    if (localStorage.getItem('user') === null) {
        next('/login');
    } else {
        next();
    }

}