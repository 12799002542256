// import config from '../config';
import axios from "axios";


export const userService = {
    login,
    logout,
    register,
    getRegisterForm,
    saveArticle,
    recoveryPassword,
    setNewPassword,
    socialLogin,
    getProfile,
    updateProfile,
    getUserArticle,
    removeSavedArticle,
};
function socialLogin(playload) {
    return axios
        .post(process.env.VUE_APP_SERVER_HOST+'/social-login', playload)
        .then(responce => {
          return  responce;


        }).catch(error => {
            return Promise.reject(error);
        });

}

function login(playload) {
    return axios
        .post(process.env.VUE_APP_SERVER_HOST+'/login', {"LoginForm": playload})
        .then(responce => {
            localStorage.setItem('user', JSON.stringify(responce['data']['user']));
            localStorage.setItem('saved_articles', JSON.stringify(responce['data']['saved_articles']));
            return responce['data']['user'];

        }).catch(error => {
            // console.log(error);
            return Promise.reject(error);
        });

    // return fetch(`${config.apiUrl}/login`, requestOptions)
    //     .then(handleResponse)
    //     .then(user => {
    //         // login successful if there's a jwt token in the response
    //         if (user.token) {
    //             // store user details and jwt token in local storage to keep user logged in between page refreshes
    //             localStorage.setItem('user', JSON.stringify(user));
    //         }
    //
    //         return user;
    //     });
}
function register(playload) {
    return axios
        .post(process.env.VUE_APP_SERVER_HOST+'/register', {"User": playload})
        .then(responce => {
          //  localStorage.setItem('user', JSON.stringify(responce['data']['user']));
            return Promise.resolve(responce['data']);

        }).catch(error => {
            // console.log(error);
            return Promise.reject(error);
        });
}



function getRegisterForm() {
    return axios
        .post(process.env.VUE_APP_SERVER_HOST+'/get-register-form')
        .then(responce => {
            return responce['data'];
        })
}

function saveArticle(playload) {

    return axios
        .post(process.env.VUE_APP_SERVER_HOST+'/save-article', {"article": playload})
        .then(responce => {
            localStorage.setItem('saved_articles', JSON.stringify(responce['data']));
            return responce['data'];
        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });

}

function logout() {
    return axios
        .get(process.env.VUE_APP_SERVER_HOST+'/logout')
        .then(responce => {
            localStorage.removeItem('user');
            return responce;

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
    // remove user from local storage to log user out

}

function recoveryPassword(playload) {

    return axios
        .post(process.env.VUE_APP_SERVER_HOST+'/recoveryPassword', {"PasswordRecoveryForm": playload})
        .then(responce => {
            // localStorage.setItem('user', JSON.stringify(responce['data']['user']));
            // localStorage.setItem('saved_articles', JSON.stringify(responce['data']['saved_articles']));
            return responce['data'];

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });


}

function setNewPassword(playload) {

    return axios
        .post(process.env.VUE_APP_SERVER_HOST+'/setNewPassword', {"ChangeOwnPasswordForm": playload})
        .then(responce => {
            // localStorage.setItem('user', JSON.stringify(responce['data']['user']));
            // localStorage.setItem('saved_articles', JSON.stringify(responce['data']['saved_articles']));
            return responce['data'];

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });


}

function getProfile() {

    return axios
        .get(process.env.VUE_APP_SERVER_HOST+'/profile')
        .then(responce => {
            return responce['data'];
        }).catch(error => {
            return Promise.reject(error);
        });


}

function updateProfile(playload){
    return axios
        .post(process.env.VUE_APP_SERVER_HOST+'/profile', {"ProfileForm": playload})
        .then(responce => {
            //  localStorage.setItem('user', JSON.stringify(responce['data']['user']));
            return Promise.resolve(responce['data']);

        }).catch(error => {
            // console.log(error);
            return Promise.reject(error);
        });
}


function getUserArticle(page) {
    return axios
        .get(process.env.VUE_APP_SERVER_HOST+'/get-users-articles?page='+page)
        .then(responce => {
            return responce['data'];
        }).catch(error => {
            return Promise.reject(error);
        });


}

function removeSavedArticle(id) {
    return axios
        .get(process.env.VUE_APP_SERVER_HOST + '/remove-saved-article?id=' + id)
        .then(response => {
            //let searchForm = playload;
            let data = response.data;
            return data;

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
}

// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 logout();
//                 location.reload(true);
//             }
//
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//
//         return data;
//     });
// }