import {authentication} from "@/store/authentication.module";
import {search} from "@/store/search.module.js";
import {users} from "@/store/users.module";
import Vue from 'vue';
import Vuex from 'vuex';
import { alert } from './alert.module';
import {overlay} from "@/store/overlay.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        alert,
        authentication,
        users,
        search,
        overlay
    }
});