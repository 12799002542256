export const overlay = {
    namespaced: true,
    state: {
        show: false,

    },
    actions: {
        show({ commit }) {
            commit('show');
        },
        hide({ commit }) {
            commit('hide');
        },
    },
    mutations: {
        show(state) {
            state.show = true;

        },
        hide(state) {
            state.show = false;
        },
    }
}