import axios from "axios";

export const searchService = {
    search,
    getData,
    getSearchForm,
    getSearchServices,
    getSearchProgress,
    getFilterFields,
    saveSearch,
    getSavedSearch,
    removeSavedSearch
};

function search(playload) {

    return axios
        .post(process.env.VUE_APP_SERVER_HOST + '/search', {"SearchForm": playload})
        .then(response => {
            let searchForm = playload;
            let data = JSON.parse(response.data);
            searchForm['searchId'] = data['id'];
            searchForm['searchHash'] = data['hash'];
            searchForm['page'] = 0;
            searchForm['articles'] = [];
            return searchForm;

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
}

function getData(playload) {
    return axios
        .post(process.env.VUE_APP_SERVER_HOST + '/search/get-data', {"SearchForm": playload})
        .then(response => {
            let searchForm = playload;
            let data = response.data;
            searchForm['page'] = data.page;
            searchForm['count'] = data.count;
            // let articles =  JSON.parse(JSON.stringify(searchForm['articles']))


            if (typeof searchForm['articles'] == "undefined") {
                searchForm['articles'] = [];
            }

            if (typeof data.articles != "undefined" && data.articles.length > 0) {
                searchForm['articles'] = searchForm['articles'].concat(data.articles);

            } else {
                searchForm['articles'] = [];
            }
            // searchForm['articles'] = searchForm['articles'].concat(data.articles);
            return searchForm;

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
}

function getSearchForm(playload) {
    return axios
        .post(process.env.VUE_APP_SERVER_HOST + '/search/get-search-form', {"searchHash": playload})
        .then(response => {
            let searchForm = response.data;
            // console.log(searchForm);
            //
            // if (typeof searchForm === 'string') {
            //     console.log(searchForm);
            //     // searchForm = JSON.parse(searchForm);
            //     console.log(JSON.parse(JSON.stringify(searchForm)));
            // }

            return searchForm;

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
}

function getSearchServices() {
    return axios
        .post(process.env.VUE_APP_SERVER_HOST + '/search/get-services')
        .then(response => {
            //let searchForm = playload;
            let data = response.data;
            return data;

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });    
}

function getSearchProgress(playload) {
    return axios
        .post(process.env.VUE_APP_SERVER_HOST + '/search/get-progress', {"SearchForm": playload})
        .then(response => {
            //let searchForm = playload;
            let data = response.data;
            return data;

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
}

function getFilterFields() {
    return axios
        .get(process.env.VUE_APP_SERVER_HOST + '/search/get-filter-fields')
        .then(response => {
            //let searchForm = playload;
            let data = response.data;
            return data;

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
}

function saveSearch(playload) {

    return axios
        .post(process.env.VUE_APP_SERVER_HOST + '/search/save-search', {"SavedSearch": playload})
        .then(response => {
            //let searchForm = playload;
            let data = response.data;
            return data;

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
}

function getSavedSearch(page) {
    return axios
        .get(process.env.VUE_APP_SERVER_HOST + '/search/get-saved-search?page=' + page)
        .then(response => {
            //let searchForm = playload;
            let data = response.data;
            return data;

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
}

function removeSavedSearch(id) {
    return axios
        .get(process.env.VUE_APP_SERVER_HOST + '/search/remove-saved-search?id=' + id)
        .then(response => {
            //let searchForm = playload;
            let data = response.data;
            return data;

        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
}
