import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css'
import './assets/css/site.css'
import './assets/css/avenir-lt-pro-cufonfonts-webfont/style.css'
import "./assets/css/gordita-cufonfonts-webfont/style.css"
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouterUserRoles from "vue-router-user-roles";
import vue_moment from "vue-moment";
import Notifications from 'vue-notification'
import router from './router'
import {store} from "@/store";
import VueSocialauth from 'vue-social-auth'
import moment from 'moment'
import VuetifyConfirm from 'vuetify-confirm'


Vue.use(VueRouterUserRoles, {router});
Vue.use(vue_moment);
Vue.use(Notifications)
Vue.use(VuetifyConfirm, { vuetify })

Vue.config.productionTip = false





new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
}).$mount('#app')

axios.defaults.withCredentials = true
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 403) {
        localStorage.removeItem('user'); //if you are using state or //something else to store auth status then remove it regarding your ''choose
        router.push('/login')
    }

    return Promise.reject(error);


});

Vue.use(vuetify, VueRouterUserRoles, router);
Vue.use(VueAxios, axios)

Vue.use(VueSocialauth, {

    providers: {
        google: {
            clientId: '1033523951967-qo8o646nc6e67h455m0cbdi66mfm42i1.apps.googleusercontent.com',
            redirectUri: process.env.VUE_APP_CURRENT_HOST + '/auth/callback' // Your client app URL
        }
    }
})

Vue.filter('formatOnlyData', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
    }
})
const DEFAULT_TITLE = 'Leita.ai';

router.afterEach((to) => {

//     // Use next tick to handle router history correctly
//     // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = DEFAULT_TITLE + " - "+ to.name || DEFAULT_TITLE;
    });
});

// Vue.use(VueGtm, {
//     id: "GTM-NVC778C", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
//     defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
//     enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//     debug: true, // Whether or not display console logs debugs (optional)
//     loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//     vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//     trackOnNextTick: false,
// });

