<template>
  <div>
    <notifications group="leita" />

    <router-view />
  </div>


</template>

<script>

  import {refreshPageMixin} from "@/mixins/RefreshPageMixin";

  export default {
    name: 'App',
    mixins: [refreshPageMixin],
  }
</script>
