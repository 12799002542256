import {userService} from "@/services/user.service";
// import router from "@/router";

let user = false;

if (typeof (localStorage.getItem('user')) !== undefined && localStorage.getItem('user') !== "undefined") {
    user = JSON.parse(localStorage.getItem('user'));

}

const initialState = user
    ? {status: {loggedIn: true}, user}
    : {status: {loggedIn: false}, user: null};

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        socialLogin({commit}, payload) {
            return userService.socialLogin(payload)
                .then(
                    responce => {
                        if (responce['status'] === 200) {
                            commit('loginSuccess', user);
                        }
                        return responce;
                        // resolve(user);
                    },
                    // error => {
                    //     return Promise.reject(error);
                    //
                    //     // if (error.response != null) {
                    //     //     commit('loginFailure', error);
                    //     //     if (error.response.status == 401) {
                    //     //         dispatch('alert/validationError', error.response.data, {root: true});
                    //     //
                    //     //     } else {
                    //     //         dispatch('alert/error', error.response.data.message, {root: true});
                    //     //
                    //     //     }
                    //     // }
                    // }

                );
            // })
        },
        login({dispatch, commit}, payload) {
            // commit('loginRequest', { email });
            // return new Promise((resolve) => {
            return userService.login(payload)
                .then(
                    user => {
                        commit('loginSuccess', user);
                        return user;
                        // resolve(user);
                    },
                    // error => {
                    //     return Promise.reject(error);
                    //
                    //     // if (error.response != null) {
                    //     //     commit('loginFailure', error);
                    //     //     if (error.response.status == 401) {
                    //     //         dispatch('alert/validationError', error.response.data, {root: true});
                    //     //
                    //     //     } else {
                    //     //         dispatch('alert/error', error.response.data.message, {root: true});
                    //     //
                    //     //     }
                    //     // }
                    // }
                ).finally(() => {

                        dispatch('overlay/hide', "", {root: true});

                    }
                );
            // })
        },
        logout({commit}) {
            userService.logout().then(() => {
                commit('logout');
            });

        },
        recoveryPassword({dispatch, commit}, payload) {
            return userService.recoveryPassword(payload)
                .then(
                    response => {
                        commit('recoveryPasswordSuccess', response);
                        return response;
                        // window.location.reload(false);
                    }
                ).finally(() => {

                        dispatch('overlay/hide', "", {root: true});

                    }
                );


        },
        setNewPassword({dispatch, commit}, payload) {
            // commit('loginRequest', { email });

            // return new Promise((resolve) => {
            return userService.setNewPassword(payload)
                .then(
                    response => {
                        commit('changeOwnPasswordSuccess', response);

                        return response;
                        // window.location.reload(false);
                    },
                    // error => {
                    //     if (error.response != null) {
                    //         // commit('setNewPasswordFailure', error);
                    //         if (error.response.status == 401) {
                    //             dispatch('alert/validationError', error.response.data, {root: true});
                    //
                    //         } else {
                    //             dispatch('alert/error', error.response.data.message, {root: true});
                    //
                    //         }
                    //
                    //     }
                    // }
                ).finally(() => {

                        dispatch('overlay/hide', "", {root: true});

                    }
                );
            // });


        },
    },
    mutations: {
        // loginRequest(state, user) {
        //     state.status = { loggingIn: false };
        //     state.user = user;
        // },
        loginSuccess(state, user) {
            state.status = {loggedIn: true};
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        },

        recoveryPasswordSuccess(state) {
            state.status = {};
            state.user = null;
        },
        changeOwnPasswordSuccess(state) {
            state.status = {changePasswordSuccess: true};

        }
    }
}