import {userService} from "@/services/user.service";

export const users = {
    namespaced: true,
    state: {
        all: {}
    },
    actions: {
        getAll({commit}) {
            commit('getAllRequest');

            userService.getAll()
                .then(
                    users => commit('getAllSuccess', users),
                    error => commit('getAllFailure', error)
                );
        },
        register({dispatch, commit}, payload) {
            return userService.register(payload)
                .then(
                    response => {
                        if (response.result) {
                            commit('registerSuccess', response);
                        }
                        return response;
                    },
                )
                .finally(() => {

                    dispatch('overlay/hide', "", {root: true});

                });
            // });
        },
        getRegisterForm() {
            return userService.getRegisterForm()
        },
        saveArticle(context, article) {
            return userService.saveArticle(article).then(data => {
                return data
            });
        },
        removeSavedArticle(context, id) {
            return userService.removeSavedArticle(id).then(data => {
                return data
            });
        },
        getUserArticle({dispatch}, page) {
            dispatch('overlay/show', "", {root: true});

            return userService.getUserArticle(page).then(data => {
                return data
            }).finally(() => {

                dispatch('overlay/hide', "", {root: true});

            });
        },
        getProfile({dispatch}) {
            return userService.getProfile()
                .then(
                    response => {
                        return response['data'];
                    },
                )
                .finally(() => {

                    dispatch('overlay/hide', "", {root: true});

                });
        },

        updateProfile({dispatch}, payload) {
            dispatch('overlay/show', "", {root: true});
            return userService.updateProfile(payload)
                .then(
                    response => {
                        return response;
                    },
                )
                .finally(() => {

                    dispatch('overlay/hide', "", {root: true});

                });
        }
    },
    mutations: {
        getAllRequest(state) {
            state.all = {loading: true};
        },
        getAllSuccess(state, users) {
            state.all = {items: users};
        },
        getAllFailure(state, error) {
            state.all = {error};
        },

        registerSuccess(state) {
            state.status = {registered: true};
            // state.user = user;
        },
    }
}