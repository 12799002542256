import {searchService} from "@/services/search.service";
import router from "@/router";

let searchForm = {'searchString': '', 'articles': [], 'page': 0, 'count': 0, 'filter': []};
const searchState = {
    'searchForm': searchForm,
    'startSearch': false,
    'searchFilterForm': {
        filterFieldsValue: '',
        searchValue: '',
        withParam: 'and',
        year: '',
        subjects: []
    },
    showAddHistoryModal: false
};

export const search = {
    namespaced: true,
    state: searchState,
    actions: {
        startSearch({commit}, payload) {
            console.log('start search');

            // searchForm['searchString'] = payload;
            searchService.search(payload)
                .then(
                    searchForm => {
                        commit('searchStarted', payload);

                        router.push("/search?hash=" + searchForm.searchHash);
                    }
                )
        },
        getSearchData({commit}) {
            var searchForm = search.state.searchForm;
            if ('searchId' in searchForm) {

                return searchService.getData(searchForm)
                    .then(
                        searchForm => {
                            commit('searchStarted', searchForm);
                            commit('setSearchFilter', {searchFilterForm: searchForm.filter})
                            return {
                                'post': searchForm.articles,
                                'count': searchForm.count,
                                'filter': searchForm.filter
                            };
                        }
                    );
            }
        },
        getSearchServices(){
            return searchService.getSearchServices().then(data => {
                return data
            });    
        },
        getSearchProgress(context, article) {
            return searchService.getSearchProgress(article).then(data => {
                return data
            });
        },
        getSearchForm({commit}, payload) {
            var searchForm = search.state.searchForm;
            if (searchForm.searchHash == payload) {
                return searchForm;
            } else {
                return searchService.getSearchForm(payload).then(storedForm => {
                    commit('searchStarted', storedForm);
                    return storedForm
                });

            }
            //return search.state.searchForm;
        },

        getFilterFields() {
            return searchService.getFilterFields().then(data => {
                return data
            });

        },
        saveSearch(context,payload) {

            return searchService.saveSearch(payload).then(data => {
                // commit('searchStarted', storedForm);
                return data
            });
        },
        getSavedSearch(context,page){
            return searchService.getSavedSearch(page).then(data => {
                return data
            });
        },

        removeSavedSearch(context, payload){
            return searchService.removeSavedSearch(payload).then(data => {
                return data
            });
        }
    },
    mutations: {
        searchStarted(state, searchForm) {
            search.state.searchForm = searchForm;

        },
        setStartSearch(state, payload) {
            state.startSearch = payload.startSearch;
        },
        setSearchFilter(state, payload) {
            let searchFilterForm = payload.searchFilterForm;
            if (searchFilterForm == null) {
                state.searchFilterForm = searchState.searchFilterForm;
            } else {
                state.searchFilterForm = payload.searchFilterForm;
            }
        },
        setShowAddHistoryModal(state, playload) {
            state.showAddHistoryModal = playload.showAddHistoryModal;

        },



    }
}